
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PartnerRegistration from "./pages/PartnerRegistration";
import PartnersList from "./pages/PartnersList";

const rooting = [{ path: "/", element: <PartnerRegistration /> },{ path: "/partners", element: <PartnersList /> }];

const router = createBrowserRouter(rooting);

export default function App(props) {
  return <RouterProvider router={router} />;
}
