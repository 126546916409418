import { Container } from "../components/utils";
import Footer from "../layouts/Footer";
import Nav from "../layouts/Nav";
import React, { useEffect, useRef, useState } from "react";
import { getAgentRegistrations } from "../services/Common";

const PartnersList = () => {
  const [agents, setAgents] = useState([]);
  const color = "light";

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    return getAgentRegistrations().then((res) => {
      if (res.code === 200) {
        setAgents(res.data);
      }
    });
  };

  return (
    <>
      <div className="w-full">
        <Nav />
        <section className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp pt-[3rem] xl:pt-[4rem]">
          <div className="bg-white absolute h-44 w-100"> </div>
          <Container>
            <div className="flex flex-col items-center justify-center py-16">
              <div className="flex flex-col items-center justify-center my-5"></div>
              <div className="items-center w-full bg-white">
                <div
                  className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light"
                      ? "bg-white"
                      : "bg-lightBlue-900 text-white")
                  }
                >
                  <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                      <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3
                          className={
                            "font-semibold text-lg " +
                            (color === "light"
                              ? "text-slate-700"
                              : "text-white")
                          }
                        >
                          Agents
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-slate-200 text-slate-500 border-slate-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Name
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-slate-200 text-slate-500 border-slate-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Designation
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-slate-200 text-slate-500 border-slate-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Mobile Number
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-slate-200 text-slate-500 border-slate-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Email
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-slate-200 text-slate-500 border-slate-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Address
                          </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {agents.map((agent) => {
                          return (
                            <tr>
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                {agent.name}
                                
                              </th>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {agent.designation}
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {agent.phone}
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <i className="fas fa-circle text-orange-500 mr-2"></i>{" "}
                                {agent.email}
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <div className="flex">  {agent.address},{agent.city},{agent.country} </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default PartnersList;
