import Nav from "../layouts/Nav";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Container, Loader, toast } from "../components/utils";
import Footer from "../layouts/Footer";
import { agentRegistrations } from "../services/Common";

const PartnerRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {}, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const d = {
      name: data.fullName,
      email: data.email,
      phone: data.mobilenumber,
      country: data.country,
      designation: data.country,
      comapany: data.company,
      city: data.city,
      address: data.address,
    };
    agentRegistrations(d).then(
      (res) => {
        console.log(res);

        const title = "Success";
        const success =
          "Thank you for submitting your details. Our team will be in touch with you shortly.";
        const ok = "Ok";
        toast(title, success, ok, "success");
        console.log(data);
        setLoading(false);
        document.getElementById("formreceipt").reset();
      },
      (err) => {
        const title = "Error";
        const success = "Something went wrong. Please try again";
        const ok = "Ok";
        toast(title, success, ok, "error");
        console.log(err);
      }
    );
  };
  return (
    <div className="w-full">
      <Nav />
      <section className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp pt-[3rem] xl:pt-[4rem]">
        <div className="bg-white absolute h-44 w-100"> </div>
        <Container>
          <div className="flex flex-col items-center justify-center py-16">
            <div className="flex flex-col items-center justify-center my-5"></div>
            <div className="items-center w-full bg-white">
              <div className="p-10 shadow-2xl w-full  bg-white">
                <div className="">
                  <div>
                    <h4 className="text-xl text-black font-medium py-3 leading-8">
                      Agent Registration
                    </h4>
                    <p className="text-sm text-gray-400 font-normal">
                      Please fill up the details and we will contact you soon
                    </p>
                  </div>
                  <div className=" ">
                    <form id="formreceipt" onSubmit={handleSubmit(onSubmit)}>
                      <div className="">
                        <div className="space-y-6 bg-white  py-5 ">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                key="fullName"
                                placeholder="Full Name"
                                aria-invalid={
                                  errors.fullName ? "true" : "false"
                                }
                                {...register("fullName", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.fullName
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.fullName && (
                                <span role="alert" className=" text-xs">
                                  Please enter your name
                                </span>
                              )}
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Designation
                              </label>
                              <input
                                type="text"
                                id="designation"
                                name="designation"
                                key="designation"
                                placeholder="Designation"
                                aria-invalid={
                                  errors.designation ? "true" : "false"
                                }
                                {...register("designation", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.designation
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.designation && (
                                <span role="alert" className=" text-xs">
                                  Please enter your designation
                                </span>
                              )}
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="mobilenumber"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Mobile Number
                              </label>
                              <PhoneInput
                                name="mobilenumber"
                                defaultCountry="AE"
                                style={{ direction: "ltr" }}
                                international
                                countryCallingCodeEditable={true}
                                onFocus={() => {
                                  setFocused(true);
                                }}
                                onBlur={() => {
                                  setFocused(false);
                                }}
                                className={` mt-1  focus:border-gold-100 px-2 w-full sm:text-sm py-0 shadow-sm  rounded-md border    ${
                                  focused
                                    ? "border-gold-100 "
                                    : errors.mobilenumber
                                    ? " border-red-600"
                                    : "border-gray-300"
                                } `}
                                control={{
                                  ...register("mobilenumber", {
                                    required: true,
                                  }),
                                }}
                                onChange={(e) => {
                                  if (e && isValidPhoneNumber(e)) {
                                    delete errors.mobilenumber;
                                    setValue("mobilenumber", e);
                                  } else {
                                    setValue("mobilenumber", "");
                                  }
                                }}
                                rules={{ required: true }}
                              />

                              {errors.mobilenumber && (
                                <span role="alert" className=" text-xs">
                                  Please enter your mobile number
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                key="email"
                                placeholder="Email"
                                aria-invalid={errors.email ? "true" : "false"}
                                {...register("email", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.email
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.email && (
                                <span role="alert" className=" text-xs">
                                  Please enter your email
                                </span>
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Company Name
                              </label>
                              <input
                                type="text"
                                id="company"
                                name="company"
                                key="company"
                                placeholder="Company Name"
                                aria-invalid={errors.company ? "true" : "false"}
                                {...register("company", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.company
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.company && (
                                <span role="alert" className=" text-xs">
                                  Please enter your company name
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Address
                              </label>
                              <input
                                type="text"
                                id="address"
                                name="address"
                                key="address"
                                placeholder="Address"
                                aria-invalid={errors.address ? "true" : "false"}
                                {...register("address", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.address
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.address && (
                                <span role="alert" className=" text-xs">
                                  Please enter your Address
                                </span>
                              )}
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-gray-700"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                id="city"
                                name="city"
                                key="city"
                                placeholder="City"
                                aria-invalid={errors.city ? "true" : "false"}
                                {...register("city", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.address
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.city && (
                                <span role="alert" className=" text-xs">
                                  Please enter your City
                                </span>
                              )}
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                              <label
                                htmlFor="country"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Country
                              </label>
                              <input
                                type="text"
                                id="country"
                                name="country"
                                key="country"
                                placeholder="Country"
                                aria-invalid={errors.country ? "true" : "false"}
                                {...register("country", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full px-3 py-2 rounded-md border shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.country
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.country && (
                                <span role="alert" className=" text-xs">
                                  Please enter your Country
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="py-3">
                          <button
                            type="submit"
                            disabled={loading}
                            className="inline-flex justify-center gap-2 text-xs items-center rounded-md border border-transparent bg-roohblue-100 py-4 px-8 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <span>Submit</span>
                            {loading && <Loader color={"white"} />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
export default PartnerRegistration;
