const BASE_URL = 'https://api.roohtourism.a2hosted.com/';
export async function getAgentRegistrations() {
  try {
    const response = await fetch(`${BASE_URL}agent-registrations`);
    return await response.json();
  } catch (error) {
    return [];
  }
}
 
export async function agentRegistrations(data) {
  const response = await fetch(`${BASE_URL}agent-registrations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}
