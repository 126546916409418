import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "../components/utils";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
const Nav = () => {
  return (
    <>
      <nav className="text-gray-600 p-2 sm:p-0 text-base xl:text-lg font-medium bg-roohblue-100 py-4  xl:pt-2 fixed w-full z-50">
        <Container className="flex flex-col xl:py-0">
          <div className="flex items-center justify-between w-full ">
            <Link to="/" className="flex gap-4 items-center">
              <div>
                <img
                  src="../assets/img/rooh-logo.png"
                  alt="rooh logo"
                  className=" h-20"
                />
              </div>
            </Link>
            <div className=" items-center gap-2 lg:gap-4 hidden md:inline-flex ">
              {/* <button
                onClick={() => {}}
                className="transition-all duration-300 px-4 font-semibold md:px-1 lg:px-4 xl:px-8 text-sm py-3 xl:py-3 bg-gold-100 text-white rounded focus:outline-none "
              >
                Contact
              </button> */}
            </div>
            <div className="md:hidden relative">
              <Menu as="div" className="relative inline-block text-left">
                {/* <Menu.Button className="inline-flex w-full justify-center items-center rounded-md  px-2   font-medium text-gold-100  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                 <FontAwesomeIcon icon={faBars} color={'#fff'} size='2x' />
                </Menu.Button> */}

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={`absolute  mt-4 p-3  w-80 sm:w-80 xs:w-96  right-0  lg:w-80 md:w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <div className="">
                      <Menu.Item>
                        <Link
                          onClick={() => {}}
                          to="/#home"
                          className={`flex transition-all underline underline-offset-8 duration-300 font-medium py-2  `}
                        >
                          <span>s</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          onClick={() => {}}
                          to="/#about"
                          className={`flex transition-all duration-300 font-medium py-2 text-gold-1`}
                        ></Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#services"
                          onClick={() => {}}
                          className={`flex transition-all duration-300 font-medium py-2 text-gold-1`}
                        ></Link>
                      </Menu.Item>
                      {/* <Menu.Item>
                      <Link
                        to="/#partners"
                        onClick={() => {
                          setCurrentSection("partners");
                        }}
                        className={`flex transition-all duration-300 font-medium py-2 ${
                          currentSection === "partners"
                            ? " text-gold-100 underline underline-offset-8"
                            : " text-gold-1"
                        }`}
                      >
                        <span>
                          {props.lang === "en"
                            ? props.enPhrase["lbl_partners"]
                            : props.arPhrase["lbl_partners"]}
                        </span>
                      </Link>
                    </Menu.Item> */}
                      <Menu.Item>
                        <Link
                          to="/#faq"
                          onClick={() => {}}
                          className={`flex transition-all duration-300 font-medium py-2 text-gold-1`}
                        ></Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#offices"
                          onClick={() => {}}
                          className={`flex transition-all duration-300 font-medium py-2 text-gold-1`}
                        ></Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/"
                          onClick={() => {}}
                          className="flex transition-all duration-300 font-medium py-2 "
                        >
                          <span>en</span>
                        </Link>
                      </Menu.Item>
                      <div className="flex items-center mt-2 gap-3">
                        <Menu.Item>
                          <Link
                            to="/register"
                            className="transition-all block text-center w-1/2 duration-300 px-3 lg:px-4 xl:px-8 font-medium py-2 xl:py-3 border border-gold-100 bg-gold-100 text-white rounded-md focus:outline-none "
                          >
                            ss
                          </Link>
                        </Menu.Item>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </Container>
      </nav>
    </>
  );
};
export default Nav;
